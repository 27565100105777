<!--
 * ======================================
 * 说明： 班主任 新增  修改
 * 作者： Silence
 * 文件： headteacherEdit.vue
 * 日期： 2023/8/11 9:50
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="form" label-width="80px" :rules="rules">
    <el-form-item label="学期" prop="semesterId">
      <el-select :disabled="!!form.id" class="w-full" v-model="form.semesterId" filterable>
        <el-option v-for="(item,index) in semesterOptions" :label="item.semester_name" :value="item.id" :key="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="老师" prop="teacherId">
      <el-select class="w-full" v-model="form.teacherId" filterable  @change="$forceUpdate()">
        <el-option v-for="(item,index) in teacherOptions" :label="item['real_name']" :value="item['id']" :key="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="班级" prop="classId">
      <el-cascader class="w-full" v-model="grade" :options="classesTree" :props="{value:'id',label:'name'}"></el-cascader>
    </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value:Object,
    },
    data() {
      return {
        semesterOptions:[],
        subjectOptions:[],
        teacherOptions:[],
        classesTree:[],
        grade:[],
        form:{
          teacherId:'',
          classId:'',
          semesterId:'',
        },
        rules:{
          teacherId:[{required: true, message: '请选择教师', trigger: 'blur'}],
          classId:[{required: true, message: '请选择班级', trigger: 'blur'}],
          semesterId:[{required: true, message: '请选择学期', trigger: 'blur'}],
        }
      }
    },
    computed: {},
    created() {},
    async mounted() {
      await this.handleLoadTeachers();  // 获取所有教师
      await this.getClassesTree();  // 获取所有班级
      await this.handleLoadSemester();  // 获取所有学期
      if(this.value.id){
        await this.handleLoadForm(this.value.id);
      }else{
        this.form = {
          teacherId:'',
          classId:'',
          semesterId:'',
        }
      }
    },
    methods: {
      async handleLoadSemester(){
        await this.$cloud.get('semester/list',{
          page:1,
          size:99999
        }).then(res=>{
          this.semesterOptions = res.children
        })
      },
      
      async handleLoadForm(id){
        await this.$cloud.get('teaching/detail',{ id }).then(res=>{
          this.form = res
          this.form.semesterId = res.semester_id
          this.form.teacherId = res.teacher_id
          this.form.classId = res.class_id
          let level = ''
          this.classesTree.map(item=>{
            if(item.children && item.children.length){
              item.children.map(part=>{
                if(part.id === this.form.classId){
                  level = item.id
                }
              })
            }
          })
          this.grade = [level,this.form.classId];  //选中的班级
        })
      },
      
      // 获取所有教师
      async handleLoadTeachers(){
        await this.$cloud.get('/teacher/list',{
          status:1,
          page:1,
          size:99999
        }).then(res=>{
          this.teacherOptions = res.children
        })
      },
      // 获取班级待选树状数据`
      async getClassesTree(){
        await this.$cloud.post('/classes/list/tree').then(res=>{
          this.classesTree = res
        })
      },
  
      // 执行数据提交接口
      handleSubmit() {
        this.form.classId = this.grade[1];
        return new Promise((resolve, reject) => {
          this.$refs['form'].validate((valid) => {
            if (valid) {
              let api = '/teaching/head/create'
              //判断是否存在ID，如果存在ID，则是修改方法
              if (this.form.id) {
                api = '/teaching/head/edit'
              }
              this.$cloud.post(api, this.form).then(res => {
                resolve(true)
              }).catch(err => {
                reject(false)
              })
            } else {
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>