<!--
 * ======================================
 * 说明： 科目  新增  更新
 * 作者： Silence
 * 文件： subjectEdit.vue
 * 日期： 2023/8/9 14:17
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="80px">
    <el-form-item label="排序">
      <el-input-number class="w-full" v-model="form.index"></el-input-number>
    </el-form-item>
    <el-form-item label="关联菜单" prop="menus">
      <el-select class="w-full" v-model="form.menus" placeholder="请选择" multiple  collapse-tags>
        <el-option v-for="item in menuList" :key="item.id" :label="item.title" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    value:Object
  },
  data() {
    return {
      form:{
        name:'',
        index:0,
        menus:[]
      },
      menuList:[],
      rules:{
        menus: [
          {required: true, message: '请选择关联菜单', trigger: 'blur'}
        ]
      }
    }
  },
  created() {},
  mounted() {
    this.handleLoadMenuList();
    console.log('id::',this.value)
    if(this.value.id){
      this.handleLoadForm(this.value.id)
    }else{
      this.form = {
        name:'',
        index:0,
        menus:[],
      }
    }
  },
  methods: {
    handleLoadMenuList(){
      this.$cloud.get('menu/list',{
        page:1,
        size:100
      }).then(res=>{
        this.menuList = res.children || []
      })
    },
    
    // 加载表单数据
    handleLoadForm(id){
      this.$cloud.get('/subject/detail',{id}).then(res=>{
        this.form = res
      })
    },
    
    // 提交数据
    handleSubmit(){
      return new Promise((resolve,reject)=>{
        this.$refs['form'].validate((valid)=>{
          if(valid){
            this.$cloud.post('/subject/edit',this.form).then(()=>{
              resolve(true)
            }).catch(()=>{
              reject(false)
            })
          }else{
            reject(false)
          }
        })
      })
    }
  },
}
</script>
<style lang="scss" scoped></style>